import React, { useEffect }/* , { useState, useRef } */ from "react"
// import axios from "axios"
// import { Player } from "video-react"
// import { useTranslation } from "react-i18next"
// import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite"

// import {
//   Box,
//   Grid,
//   List,
//   Paper,
//   ListItem,
//   Container,
//   Typography,
//   makeStyles,
//   IconButton,
//   CircularProgress,
// } from "@material-ui/core"

// import "../components/layout.css"
// import SEO from "../components/seo"
// import "../../node_modules/video-react/dist/video-react.css"

// import Logo from "../../static/images/logo/logo_signup.svg"
// import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
// import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"
// import FooterCategory1 from "../../static/images/Icons/CategoryIcons1.svg"
// import FooterCategory2 from "../../static/images/Icons/CategoryIcons2.svg"
// import FooterCategory3 from "../../static/images/Icons/CategoryIcons3.svg"

const windowGlobal = typeof window !== "undefined" && window

// const useStyles = makeStyles(theme => ({
//   heroOnMobile: {
//     textAlign: "center",
//     [theme.breakpoints.down("md")]: {
//       display: "none",
//     },
//   },
//   textCenter: {
//     textAlign: "center",
//   },
//   heroStoreIcons: {
//     width: "10rem",
//     margin: "2rem 0.4rem",
//   },
//   heroBoxes: {
//     margin: theme.spacing(2),
//     marginTop: "initial",
//     "& img": {
//       height: "4.5rem",
//       width: "3.5rem",
//       margin: "0 auto 5px",
//     },
//   },
//   heroLogo: {
//     width: "9.6rem",
//     height: "auto",
//   },
//   heroSecondSec: {
//     margin: "3.5rem 0",
//   },
//   heroText: {
//     margin: "2rem 0.4rem 0",
//     color: "#003e96",
//     "& h6": {
//       lineHeight: "34px",
//       fontWeight: "900",
//     },
//   },
//   // videos Styles
//   direction: "rtl",
//   typography: {
//     fontFamily: "Cairo, Arial",
//   },
//   ".MuiContainer-root": {
//     color: "red !important",
//   },
//   mainContainer: {
//     maxWidth: "100%",
//     padding: "0.5rem 0 0.4rem",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     textAlign: "center",
//     minHeight: "100vh",
//   },
//   playList: {
//     overflow: "hidden scroll",
//     maxHeight: 350,
//     width: "100%",
//     "& li": {
//       paddingTop: 12,
//       paddingBottom: 12,
//     },
//     "& li:hover": {
//       backgroundColor: "#f0f8ff",
//       cursor: "pointer",
//     },
//   },
//   playingNow: {
//     backgroundColor: "#f0f8ff",
//   },
//   audiosList: {
//     width: "100%",
//   },
//   downloadBtn: {
//     position: "absolute",
//     right: 8,
//   },
//   mainContainerForVideos: {
//     marginTop: 30,
//     marginBottom: 50,
//     maxWidth: 600,
//   },
//   visibleNotFound: {
//     display: "block !important",
//   },
// }))

const NotFoundPageOld = () => {
  
  useEffect(() => {
    windowGlobal.location = '/books/promotions/index.html'
  }, [])

  return null

//   const classes = useStyles()
//   const VideoTest = useRef(null)
//   const { t, i18n } = useTranslation()
//   const [dataFromUrl, setDataFromUrl] = useState([])
//   const [loadingContent, setLoadingContent] = useState(true)
//   const [testVisibilty, setTestVisibilty] = useState("none")
//   const [currentMusicIndex, setCurrentMusicIndex] = useState(0)
//   const [linkToPlay, setLinkToPlay] = useState()

//   let pathFromSearchBar
//   let pathFormSearchBarNoSlash
//   let pathFormSearchBarWithSlash

//   async function fetchData() {
//     if (windowGlobal) {
//       pathFromSearchBar = windowGlobal.location.href
//       pathFormSearchBarWithSlash = pathFromSearchBar.endsWith("/")
//         ? pathFromSearchBar
//         : pathFromSearchBar + "/"

//       pathFormSearchBarNoSlash = pathFromSearchBar.endsWith("/")
//         ? pathFromSearchBar.slice(0, -1)
//         : pathFromSearchBar
//     }
    
//     await axios
//       .get(
//         `${process.env.GATSBY_API_URL}/old-routes/?old_route=${
//           pathFromSearchBar && pathFromSearchBar
//         }&old_route=${
//           pathFromSearchBar && pathFromSearchBar.substring(7)
//         }&old_route=${
//           pathFromSearchBar && pathFromSearchBar.substring(8)
//         }&old_route=${
//           pathFormSearchBarWithSlash && pathFormSearchBarWithSlash
//         }&old_route=${pathFormSearchBarWithSlash.substring(
//           7
//         )}&old_route=${pathFormSearchBarWithSlash.substring(8)}&old_route=${
//           pathFormSearchBarNoSlash && pathFormSearchBarNoSlash
//         }&old_route=${
//           pathFormSearchBarNoSlash && pathFormSearchBarNoSlash.substring(7)
//         }&old_route=${
//           pathFormSearchBarNoSlash && pathFormSearchBarNoSlash.substring(8)
//         }`
//       )
//       .then(res => {
//         setDataFromUrl(res.data)
//         setLoadingContent(false)
//         setTestVisibilty(
//           res.data.length === 0 && loadingContent ? "block" : "none"
//         )
//       })
//       .catch(err => {
//         setLoadingContent(false)
//       })
//   }

//   useEffect(() => {
//     setLinkToPlay()
//     fetchData()
//   }, [windowGlobal && windowGlobal.location.href])

//   const handlePlaylistItemClikc = (itemLink, itemNumber) => {
//     setLinkToPlay(itemLink.new_url)
//     setCurrentMusicIndex(itemNumber)
//   }

//   return (
//     <>
//       <SEO
//         title={`404: ${t("errors.notFoundPageTitle")}`}
//         lang={i18n.language}
//       />
//       <Container
//         className={` ${
//           (dataFromUrl && dataFromUrl.length === 0) || loadingContent
//             ? classes.mainContainer
//             : classes.mainContainerForVideos
//         }`}
//       >
//         {loadingContent && (
//           <Container>
//             <CircularProgress />
//           </Container>
//         )}
//         {!loadingContent && dataFromUrl.length > 0 ? (
//           <Container>
//             <Grid
//               container
//               directoin="row"
//               justify="center"
//               alignItems="center"
//             >
//               <Grid
//                 item
//                 // md={6}
//                 xs={12}
//                 className={`${classes.textCenter}`}
//               >
//                 <Player
//                   src={
//                     linkToPlay
//                       ? linkToPlay
//                       : dataFromUrl && dataFromUrl.length === 1
//                       ? dataFromUrl[0].new_urls[0].new_url
//                       : dataFromUrl.new_urls[0].new_url
//                   }
//                   ref={VideoTest}
//                 />
//                 {/* {videosUrls && ( */}
//                 <Paper className={classes.playList}>
//                   <List>
//                     {dataFromUrl &&
//                       dataFromUrl.length === 1 &&
//                       dataFromUrl
//                         .map(item => item.new_urls)
//                         .map(item =>
//                           item.map((item, key) => (
//                             <ListItem
//                               key={key}
//                               onClick={() => handlePlaylistItemClikc(item, key)}
//                               className={`${
//                                 key === currentMusicIndex
//                                   ? classes.playingNow
//                                   : ""
//                               } ${classes.audiosList}`}
//                               style={{
//                                 flex: 1,
//                                 backgroundImage: `url(${process.env.GATSBY_API_URL}${
//                                   item.image && item.image.formats.large.url
//                                 })`,
//                                 backgroundSize: "100% 100%",
//                                 backgroundPosition: "center",
//                               }}
//                             >
//                               <div style={{ flex: 1, display: "flex" }}>
//                                 <div>{!item.image && <> {key + 1}- </>}</div>
//                                 <div>
//                                   {
//                                     !item.image &&
//                                       (item.new_url.substring(
//                                         item.new_url.lastIndexOf("/") + 1,
//                                         item.new_url.lastIndexOf(".mp4")
//                                       ),
//                                       item.new_url.substring(
//                                         item.new_url.lastIndexOf("/") + 1,
//                                         item.new_url.lastIndexOf(".mp4")
//                                       ))
//                                     // item.new_url.includes(".mp4") && "asdsdsfdsdf",
//                                     // item.new_url.substring(
//                                     //   item.new_url.lastIndexOf("/") + 1,
//                                     //   item.new_url.lastIndexOf(".mp4")
//                                     // ),
//                                     // item.new_url.includes(".mp3") &&
//                                     //   item.new_url.substring(
//                                     //     item.new_url.lastIndexOf("/") + 1,
//                                     //     item.new_url.lastIndexOf(".mp3")
//                                     //   )
//                                   }
//                                 </div>
//                               </div>
//                               {dataFromUrl && dataFromUrl[0].new_urls.length && (
//                                 <IconButton
//                                   color="primary"
//                                   aria-label="Best audio"
//                                 >
//                                   <PlayCircleFilledWhiteIcon />
//                                 </IconButton>
//                               )}
//                             </ListItem>
//                           ))
//                         )}
//                   </List>
//                 </Paper>
//                 {/* )} */}
//               </Grid>
//             </Grid>
//           </Container>
//         ) : (
//           <Container style={{ display: testVisibilty }}>
//             <Grid
//               container
//               directoin="row"
//               justify="center"
//               alignItems="center"
//             >
//               <Grid
//                 item
//                 md={6}
//                 xs={12}
//                 className={`${classes.textCenter} ${classes.heroOnMobile}`}
//               >
//                 <Typography variant="h2" component="h2">
//                   {t("errors.notFoundPageTitle")}
//                 </Typography>
//                 <Typography variant="h6" component="h2">
//                   {t("errors.notFoundPageText")}{" "}
//                   {/* &#39;t exist... the sadness. */}
//                 </Typography>
//               </Grid>
//               <Grid
//                 item
//                 md={6}
//                 xs={12}
//                 className={`${classes.textCenter} ${classes.heroSecondSec}`}
//               >
//                 <img src={Logo} alt="hero logo" className={classes.heroLogo} />
//                 <Typography component="div" className={classes.heroText}>
//                   <Typography variant="h6">{t("home.heroText1")}</Typography>
//                   <Typography variant="h6">{t("home.heroText2")}</Typography>
//                 </Typography>
//                 <Box display="flex" justifyContent="center">
//                   <Box className={classes.heroStoreIcons}>
//                     <a href="/#">
//                       <img src={StoreIcons1} alt="Google Play" />
//                     </a>
//                   </Box>
//                   <Box className={classes.heroStoreIcons}>
//                     <a href="/#">
//                       <img src={StoreIcons2} alt="App Store" />
//                     </a>
//                   </Box>
//                 </Box>
//                 <Box display="flex" justifyContent="center">
//                   <Box p={1} m={1} className={classes.heroBoxes}>
//                     <img src={FooterCategory1} alt="Foliar" />
//                     <Typography variant="subtitle1">
//                       {t("footer.foliar")}
//                     </Typography>
//                   </Box>
//                   <Box p={1} m={1} className={classes.heroBoxes}>
//                     <img src={FooterCategory2} alt="Electronic" />
//                     <Typography variant="subtitle1">
//                       {t("footer.electronic")}
//                     </Typography>
//                   </Box>
//                   <Box p={1} m={1} className={classes.heroBoxes}>
//                     <img src={FooterCategory3} alt="Vocal" />
//                     <Typography variant="subtitle1">
//                       {t("footer.vocal")}
//                     </Typography>
//                   </Box>
//                 </Box>
//               </Grid>
//             </Grid>
//           </Container>
//         )}
//       </Container>
//     </>
//   )
}

export default NotFoundPageOld
